<template>
  <f7-page name="more">
    
    <f7-navbar
      class="app-header"
      no-hairline
      transparent
    >
      <div class="brand">
        <img src="static/brand.png" />
      </div>
    </f7-navbar>

    <zone-header />
    
    <f7-block>
      <h1 class="page-title">Altre sezioni</h1>

      <f7-list class="more-pages">
        <f7-list-item
          title="Cambia comune/zona"
          :link="`/change-zone/`"
        >
        </f7-list-item>

        <f7-list-item
          title="Gestione notifiche"
          :link="`/notifications/`"
        >
        </f7-list-item>

        <f7-list-item
          title="Centri di raccolta e contenitori stradali"
          :link="`/garbage-centers/`"
        >
        </f7-list-item>

        <f7-list-item
          v-for="(page) in pages"
          :key="page.id"
          :link="`/pages/${page.id}/`"
        >
          <span class="item-title" v-html="page.title.rendered"></span>
        </f7-list-item>

        <template v-if="loading">
          <f7-list-item
            v-for="n in 3"
            :key="n"
            class="skeleton-text skeleton-effect-blink"
            title="Full Name del rifiuto"
          />
        </template>

      </f7-list>

      <f7-block class="version">
        Version {{ $f7.version }}
      </f7-block>

    </f7-block>
  </f7-page>
</template>

<style lang="less">
  .more-pages .item-title {
    font-weight: 800;
  }

  .version {
    color: #ccc;
    text-align: center;
    margin: 30px 0 50px;
  }
</style>

<script>
  export default {
    props: {
      back: {
        type: Boolean,
        default: false,
      },
    },
    
    computed: {
      pages() {
        return this.$store.state.pages.data
      },

      loading() {
        return this.$store.state.pages.loading
      },
    },
    
    mounted() {
      this.$store
        .dispatch('fetchPages')
    }
  };
</script>
