<template>
  <f7-page 
    name="garbage-center"
    ptr @ptr:refresh="refreshPosts"
  >
    
    <f7-navbar
      class="app-header"
      no-hairline
      :back-link="showBack"
    >
      <div class="brand">
        <img src="static/brand.png" />
      </div>
    </f7-navbar>

    <zone-header />
    
    <f7-block>
      <h1 class="page-title">Centri di raccolta e contenitori stradali</h1>
    </f7-block>
    
    <f7-block v-if="loading">
      <f7-list>
        <f7-list-item
          v-for="n in 3"
          :key="n"
          class="skeleton-text skeleton-effect-blink"
          title="Full Name del rifiuto"
        >
        </f7-list-item>
      </f7-list>
    </f7-block>

    <f7-block v-else-if="error">
      <div class="error-wrapper">
        C'è stato un errore nel recupero dei centri di raccolta:
        <i>"{{ error.message }}"</i>
      </div>
    </f7-block>

    <f7-block v-else-if="garbageCenters.length === 0">
      Nessun elemento trovato.
    </f7-block>

    <f7-block v-else>
      <f7-list class="more-pages">
        <f7-list-item
          v-for="(center) in garbageCenters"
          :key="center.id"
          :title="center.title.rendered"
          :link="`/garbage-centers/${center.id}/`"
        >
        </f7-list-item>
      </f7-list>
    </f7-block>

  </f7-page>
</template>

<script>
  import { mapState } from 'vuex'
  
  export default {
    data: function () {
      return {
        showBack: true,
      };
    },

    methods: {
      refreshPosts(done) {
        this.$store.dispatch('fetchGarbageCenters')
          .then(() => {
            done();
          })
      },
    },
    
    computed: mapState({
      garbageCenters: state => state.garbageCenters.data,
      loading: state => state.garbageCenters.loading,
      error: state => state.garbageCenters.error,
    }),
    
    mounted() {
      this.$store
        .dispatch('fetchGarbageCenters')

      this.showBack = this.$f7router.history.length > 0
    }
  };
</script>
