<template>
  <f7-link no-link-class :href="`/news/${post.id}/`">
    <div class="post-teaser">
      <h3 class="post-title" v-html="post.title.rendered">
      </h3>
      <div class="post-excerpt">
        <p v-html="post.excerpt.rendered">
        </p>
      </div>

      <f7-row>
        <f7-col>
          <span class="post-date">
            {{ post.date | moment('DD/MM/Y') }}
          </span>
        </f7-col>
        <f7-col>
          <button class="read-more">
            Leggi di più
          </button>
        </f7-col>
      </f7-row>
    </div>
  </f7-link>
</template>

<style lang="less" scoped>
  .post-teaser {
    border-bottom: 1px solid #eee;
    padding: 20px 0;

    .post-title {
      font-weight: 800;
      font-size: 18px;
      color: black;
      margin: 0 0 10px;
    }

    .post-excerpt {
      font-size: 15px;
      color: #888;
      font-weight: 500;
      margin-bottom: 20px;

      p {
        margin: 0;
      }
    }

    .post-date {
      font-display: 500;
      color: #999;
    }

    .read-more {
      background: transparent;
      border: 0;
      text-align: right;
      font-weight: 900;
      font-size: 15px;
      text-decoration: underline;
    }
  }
</style>

<script>
  export default {
    props: {
      post: {
        type: Object,
        required: true,
      },
    },
  }
</script>