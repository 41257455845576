<template>
  <f7-page name="single-page">
    
    <f7-navbar
      back-link
      class="app-header"
      no-hairline
      transparent
    >
      <div class="brand">
        <img src="static/brand.png" />
      </div>
    </f7-navbar>
    <div>

      <f7-block v-if="loading" class="skeleton-text skeleton-effect-blink">
        <h1 class="page-title">
          Prima riga del titolo<br />
          Seconda riga del titolo<br />
        </h1>
      </f7-block>

      <f7-block v-else-if="error">
        <div class="error-wrapper">
          C'è stato un errore nel recupero delle informazioni:
          <i>"{{ error.message }}"</i>
        </div>
      </f7-block>

      <f7-block v-else-if="page">        
        <h1 class="page-title" v-html="page.title.rendered">
        </h1>

        
        <div class="content">
          <div class="gutenberg-embed" v-html="page.content.rendered">
          </div>
          
          <form-report v-if="page.template === 'report'" type="report" />
          <form-report v-if="page.template === 'withdrawal'" type="withdrawal" />
          
        </div>
      </f7-block>
    </div>
  </f7-page>
</template>

<style lang="less" scoped>
  .content {
    font-size: 17px;
    line-height: 1.35;
  }
</style>

<script>
  export default {
    computed: {
      page() {
        const pageId = this.$f7route.params.id;
        
        return this.$store.getters.singlePage(pageId)
      },

      loading() {
        return this.$store.state.page.loading
      },

      error() {
        return this.$store.state.page.error
      },
    },
    
    mounted() {
      const pageId = this.$f7route.params.id;

      this.$store
        .dispatch('fetchPage', pageId)
    }
  };
</script>
