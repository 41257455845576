<template>
  <div class="wrapper">
    <div class="text-wrapper">
      <h4>
        Promemoria raccolte
      </h4>
      <p>
        Avvisami il giorno prima del ritiro.
      </p>
    </div>
    <f7-toggle
      :checked="calendarValue"
      :disabled="toggleDisabled"
      @toggle:change="handleChangeMemo"
    ></f7-toggle>
  </div>
</template>

<style lang="less" scoped>
  .wrapper {
    background-color: var(--f7-theme-color-light);
    padding: 20px;
    border: 0;
    color: rgba(white, 0.6);
    display: flex;
    justify-content: space-between;
    align-items: center;

    .text-wrapper {
      color: white;
      padding-right: 10px;

      h4 {
        font-weight: 900;
        font-size: 18px;
        color: white;
        margin: 0;
      }

      p {
        margin: 0;
        font-size: 15px;
      }
    }
  }
</style>

<script>
  export default {
    props: {
      back: {
        type: Boolean,
        default: false,
      },
    },
    
    methods: {
      handleChangeMemo(value) {
        console.log('handleChangeMemo', value)
        const commitName = value ? 'addPushCategory' : 'removePushCategory'

        this.$store.commit(commitName, 'calendar')
      }
    },

    computed: {
      calendarValue() {
        return this.$store.getters.pushCategoryValue('calendar')
      },

      toggleDisabled() {
        return this.$store.state.pushFetching
      }
    },
  }
</script>