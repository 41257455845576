import moment from 'moment'

export const getNextDays = numberOfDays => {
  const array = new Array(numberOfDays).fill()
  const days = array.map((el, index) => {
    return moment().add(index, 'days').format('Y-M-D')
  })

  return days
}

export const getBlockFromPages = pages => {

  const blocks = pages.map((page) => {
    return {
      title: page.title.rendered,
      href: `/pages/${page.id}`,
      icon: page.icon,
    }
  })

  return blocks
}


export const objectToFormData = function(obj, form, namespace) {
    
  var fd = form || new FormData();
  var formKey;
  
  for(var property in obj) {
    if(obj.hasOwnProperty(property)) {
      
      if(namespace) {
        formKey = namespace + '[' + property + ']';
      } else {
        formKey = property;
      }
     
      // if the property is an object, but not a File,
      // use recursivity.
      if(typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
        
        objectToFormData(obj[property], fd, property);
        
      } else {
        
        // if it's a string or a File object
        fd.append(formKey, obj[property]);
      }
      
    }
  }
  
  return fd;
    
}