<template>
  <f7-page name="home">

    <f7-navbar
      class="app-header"
      no-hairline
      transparent
    >
      <div class="brand">
        <img src="static/brand.png" />
      </div>
    </f7-navbar>

    <zone-header />

    <!-- Page content-->
    <post-sticky />

    <!-- Search -->
    <f7-link
      no-link-class
      @click="$root.goToView('#tab-search')"
    >
      <button-search />
    </f7-link>

    <!-- Prossime raccolte -->
    <f7-block>
      <h3 class="section-title">
        Prossime raccolte:
      </h3>
    </f7-block>
    <next-collection :days="3" />

    <!-- Altri blocchi -->
    <home-blocks />

  </f7-page>
</template>

<script>

  export default {
    mounted: () => {
      console.log('mounted')
    }
  };
</script>