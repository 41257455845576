<template>
  <f7-page 
    name="news"
    ptr @ptr:refresh="refreshPosts"

    infinite
    :infinite-distance="50"
    :infinite-preloader="showInfinitePreloader && !finish"
    @infinite="loadMore"
  >
    
    <f7-navbar
      class="app-header"
      no-hairline
      :back-link="showBack"
    >
      <div class="brand">
        <img src="static/brand.png" />
      </div>
    </f7-navbar>

    <zone-header />
    
    <f7-block>
      <h1 class="page-title">News</h1>
    </f7-block>
    
    <f7-block>
      <div  v-if="posts.length">
        <post-teaser
          v-for="(post) in posts"
          :key="post.id"
          :post="post"
        ></post-teaser>
      </div>
      <p v-else>
        Non sono presenti news e avvisi
      </p>
    </f7-block>


    <div class="error-wrapper" v-if="error">
      C'è stato un errore nel recupero degli aggiornamenti:
      <i>"{{ error.message }}"</i>
    </div>

  </f7-page>
</template>

<script>
  import { mapState } from 'vuex'
  
  export default {
    data: function () {
      return {
        allowInfinite: false,
        showInfinitePreloader: true,
        showBack: true,
      };
    },

    methods: {
      refreshPosts(done) {
        this.$store.dispatch('resetPosts')
          .then(() => {
            done();
          })
      },

      loadMore(done) {
        if (!this.allowInfinite || this.finish) return;
        this.allowInfinite = false;

        this.$store.dispatch('fetchPosts', true)
          .then(() => {
            this.allowInfinite = true;
          })
        
      },
    },
    
    computed: mapState({
      posts: state => state.posts.data,
      finish: state => state.posts.finish,
      loading: state => state.posts.loading,
      error: state => state.posts.error,
    }),
    
    mounted() {
      this.$store
        .dispatch('resetPosts')
        .then(() => {
          this.allowInfinite = true
        })

      this.showBack = this.$f7router.history.length > 0
    }
  };
</script>
