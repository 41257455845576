<template>
  <f7-page>
    <f7-popup
      id="change-zone-popup"
      swipeToClose
      @popup:open="handleOpen"
      @popup:closed="handleClosed"
    >
      <f7-view>
        <f7-page>
          <f7-navbar transparent>
            <h3 class="popup-title">
              Passaggio {{ step }} di 2
            </h3>
            <f7-nav-right v-if="allowClose">
              <f7-link popup-close>
                <f7-icon f7="multiply" size="22" color="primary"></f7-icon>
              </f7-link>
            </f7-nav-right>
          </f7-navbar>

          <change-zone1
            :municipality="municipality && municipality.id"
            :handleSelect="handleSelectMunicipality"
            v-if="step === 1"
          />
          <change-zone2
            :street="street && street.id"
            :municipality="municipality && municipality.id"
            :handleSelect="handleSelectStreet"
            v-if="step === 2"
          />

          <f7-toolbar bottom :hidden="!allowNext" class="popup-toolbar" color="white">
            <f7-link
              class="cancel-link"
              @click="handelCancel"
              v-if="step === 1"
            >
              Annulla
            </f7-link>
            <f7-link
              class="next-link"
              @click="handleNext"
              v-if="step === 1"
            >
              Avanti
              <f7-icon f7="arrow_right" size="20"></f7-icon>
            </f7-link>

            <f7-link
              class="cancel-link"
              @click="handelPrev"
              v-if="step === 2"
            >
              Indietro
            </f7-link>
            <f7-link
              class="next-link"
              @click="handleConfirm"
              v-if="step === 2"
            >
              Conferma
              <f7-icon f7="checkmark_alt" size="20"></f7-icon>
            </f7-link>
          </f7-toolbar>

        </f7-page>
      </f7-view>
    </f7-popup>
  </f7-page>
</template>

<style lang="less">
  #change-zone-popup {
    .navbar {
      .popup-title {
        margin-left: 10px;
        color: #999 !important;
        font-size: 18px;
      }
    }
  }

  .popup-toolbar {
    background-color: var(--f7-theme-color-secondary) !important;
  }

  .next-link {
    font-weight: 700;

    .icon {
      margin-left: 7px;
    }
  }

  .cancel-link {
    font-weight: 700;
    opacity: 0.7;
  }
</style>

<script>
  import ChangeZone1 from './change-zone-1.vue'
  import ChangeZone2 from './change-zone-2.vue'
  
  export default {
    components: {
      ChangeZone1,
      ChangeZone2,
    },

    data() {
      return {
        step: 1,
        municipality: null,
        street: null,
        zone: null,
        allowClose: true,
      }
    },

    methods: {
      reset() {
        this.step = 1
        this.municipality = null
        this.street = null
        this.zone = null
      },

      handleOpen() {
        this.reset()
      },

      handleClosed() {
        this.step = 1
      },

      handleSelectMunicipality(municipality) {
        this.municipality = municipality
      },

      handleSelectStreet(street) {
        this.street = street
        this.zone = street['zone-id']
      },

      handleNext() {
        this.step = 2
      },

      handelPrev() {
        this.step = 1
      },

      handelCancel() {
        this.municipality = 0
      },

      handleConfirm() {
        this.$store
          .dispatch('setDeviceZone', this.zone)

        this.$store
          .dispatch('setDeviceMunicipality', this.municipality)

        this.$store
          .dispatch('setDeviceStreet', this.street)

        this.popup.close()
      }
    },

    computed: {
      initialZone() {
        return this.$store.state.device.zone
      },

      allowNext() {
        if (this.step === 1) {
          return this.municipality
        }

        if (this.step === 2) {
          return this.street && this.zone
        }

        return false
      }
    },

    mounted() {
      this.$f7ready((f7) => {
        this.popup = this.$f7.popup.get('#change-zone-popup')
      });
    }
  };
</script>
