<template>
  <f7-page name="notifications-page">
    
    <f7-navbar
      back-link
      class="app-header"
      no-hairline
      transparent
    >
      <div class="brand">
        <img src="static/brand.png" />
      </div>
    </f7-navbar>
    
    <zone-header />
    
    <f7-block>
      <h1 class="page-title">Gestione notifiche</h1>
    
      <f7-list class="more-pages">
        <f7-list-item
          title="News"
        >
          <f7-toggle slot="after"
            :checked="updatesValue"
            :disabled="toggleDisabled"
            @toggle:change="handleChangeUpdates"
          ></f7-toggle>
        </f7-list-item>

        <f7-list-item
          title="Promemoria raccolte"
        >
          <f7-toggle slot="after"
            :checked="calendarValue"
            :disabled="toggleDisabled"
            @toggle:change="handleChangeCalendar"
          ></f7-toggle>
        </f7-list-item>

      </f7-list>
    </f7-block>

  </f7-page>
</template>

<script>
  import idx from 'idx'

  export default {
    computed: {
      // Toggles values
      updatesValue() {
        return this.$store.getters.pushCategoryValue('updates')
      },

      calendarValue() {
        return this.$store.getters.pushCategoryValue('calendar')
      },

      toggleDisabled() {
        return this.$store.state.pushFetching
      }
    },

    methods: {
      handleChangeUpdates(value) {
        console.log('handleChangeUpdates', value)
        const commitName = value ? 'addPushCategory' : 'removePushCategory'

        this.$store.commit(commitName, 'updates')
      },

      handleChangeCalendar(value) {
        console.log('handleChangeCalendar', value)
        const commitName = value ? 'addPushCategory' : 'removePushCategory'

        this.$store.commit(commitName, 'calendar')
      },
    },
    
    mounted() {
    }
  };
</script>
