// Import Vue
import Vue from 'vue';
import Vuex from 'vuex'
import moment from 'moment'
import 'vue-moment'
import Fragment from 'vue-fragment'

// Import Framework7
import Framework7 from 'framework7/framework7-lite.esm.bundle.js';

// Import Framework7-Vue Plugin
import Framework7Vue from 'framework7-vue/framework7-vue.esm.bundle.js';

// Import Framework7 Styles
import 'framework7/css/framework7.bundle.css';

// Import Icons and App Custom Styles
import '../css/icons.css';
import '../css/muli.css';
import '../css/app.less';

// Import App Component
import App from '../components/app.vue';
import store from '../store/index'

// Custom components
import ZoneHeader from '../components/zone-header.vue';
import PostSticky from '../components/post-sticky.vue';
import ButtonSearch from '../components/button-search.vue';
import NextCollection from '../components/next-collection.vue';
import NextCollectionSingleGarbage from '../components/next-collection-single-garbage.vue';
import HomeBlocks from '../components/home-blocks.vue';
import PostTeaser from '../components/post-teaser.vue';
import AlertCollectionsBar from '../components/alert-collections-bar.vue';
import ChangeZonePopup from '../components/change-zone-popup.vue';
import InitialConfig from '../components/intial-config.vue';
import FormReport from '../components/form-report.vue';

// Init Framework7-Vue Plugin
Framework7.use(Framework7Vue);

moment.locale('it');

Vue.use(Vuex)
Vue.use(Fragment.Plugin)
Vue.use(require('vue-moment'),{
  moment
});

Vue.component('zone-header', ZoneHeader);
Vue.component('post-sticky', PostSticky);
Vue.component('button-search', ButtonSearch);
Vue.component('next-collection', NextCollection);
Vue.component('next-collection-single-garbage', NextCollectionSingleGarbage);
Vue.component('home-blocks', HomeBlocks);
Vue.component('post-teaser', PostTeaser);
Vue.component('alert-collections-bar', AlertCollectionsBar);
Vue.component('change-zone-popup', ChangeZonePopup);
Vue.component('initial-config', InitialConfig);
Vue.component('form-report', FormReport);

// Init App
new Vue({
  el: '#app',
  render: (h) => h(App),

  store,


  methods: {
    goToView(tabSelector) {
      if (tabSelector) {
        this.$$(tabSelector).click()
      }
    }
  },

  // Register App Component
  components: {
    app: App
  },
});