<template>
  <div>
    <f7-block>
      <h1 class="page-title">Seleziona Via</h1>
    </f7-block>

    <f7-block class="sticky">
      <div class="input-wrapper">
        <f7-input
          id="search-street-input"
          type="text"
          :value="search"
          @input="onSearchStreets"
          placeholder="Cerca via"
          class="input"
          clear-button
        ></f7-input>

        <f7-icon f7="search" size="24px" color="primary" class="icon"></f7-icon>
      </div>
    </f7-block>

    <f7-block>
      <f7-list v-if="loading">
        <f7-list-item
          v-for="n in 3"
          :key="n"
          class="skeleton-text skeleton-effect-blink"
          title="Full Name della strada"
        >
        </f7-list-item>
      </f7-list>

      <div class="error-wrapper" v-else-if="error">
        C'è stato un errore nel recupero della lista:
        <i>"{{ error.message }}"</i>
      </div>

      <f7-list v-else>
        <f7-list-item
          v-for="(s) in streets"
          :key="s.id"
          @click="handleSelect(s)"
          :class="street === s.id ? 'selected' : ''"
        >
          <span v-html="s.title && s.title.rendered" slot="inner-start"></span>
          <f7-icon
            v-if="street === s.id ? 'selected' : ''"
            slot="after"
            f7="checkmark_alt"
            size="18"
            color="primary"
          />
        </f7-list-item>

        <div
          v-if="streets.length === 0"
          class="no-results"
        >
          Nessun risultato
        </div>
      </f7-list>

    </f7-block>
  </div>
</template>

<style lang="less" scoped>
  .page-title {
    color: black;
  }

  .sticky {
    background-color: white;
    position: sticky;
    padding: 10px 20px;
    z-index: 100;
    top: 0;
  }

  .input-wrapper {
    background-color: white;
    position: relative;
    border: 1px solid #ccc;
    padding: 15px;
    padding-left: 40px;
    border-radius: 7px;

    .input {
      font-size: 16px;
  
      ::placeholder {
        color: #999;
      }
    }

    .icon {
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
    }
  }

  .no-results {
    color: #999;
  }
</style>

<script>
  import debounce from 'lodash.debounce'

  export default {
    props: {
      handleSelect: {
        type: Function,
        required: true,
      },

      municipality: {
        type: Number,
        required: true,
      },

      street: {
        type: Number,
        default: null,
      },
    },

    data() {
      return {
        search: '',
      }
    },

    methods: {
      onSearchStreets: debounce( function ({ target: { value } }) {
        this.search = value

        this.$store
          .dispatch('fetchStreets', {
            municipality: this.municipality,
            search: value,
          })
        
      }, 300),
    },

    computed: {
      streets() {
        return this.$store.state.streets.data
      },

      loading() {
        return this.$store.state.streets.loading
      },

      error() {
        return this.$store.state.streets.error
      },
    },

    mounted() {
      this.$store
        .dispatch('fetchStreets', {
          municipality: this.municipality
        })

      this.$$('#search-street-input input').focus()
    },
  };
</script>
