<template>
  <f7-app :params="f7params">
    <!-- Change zone Popup -->
    <change-zone-popup />

    <!-- Views/Tabs container -->
    <f7-views tabs class="safe-areas" v-if="zone && online">
      <!-- Tabbar for switching views-tabs -->
      <f7-toolbar tabbar bottom no-shadow class="main-bottom-bar">
        <f7-link
          icon-only
          id="tab-home"
          tab-link="#view-home"
          tab-link-active
          icon-f7="house_fill"
        ></f7-link>
        <f7-link
          icon-only
          id="tab-calendar"
          tab-link="#view-calendar"
          icon-f7="calendar"
        ></f7-link>
        <f7-link
          icon-only
          id="tab-search"
          tab-link="#view-search"
          icon-f7="search"
        ></f7-link>
        <f7-link
          icon-only
          id="tab-news"
          tab-link="#view-news"
          icon-f7="doc_text"
        ></f7-link>
        <f7-link
          icon-only
          id="tab-more"
          tab-link="#view-more"
          icon-material="more_horiz"
        ></f7-link>
      </f7-toolbar>

      <!-- VIEWS -->
      <f7-view id="view-home" main tab tab-active url="/"></f7-view>
      <f7-view
        id="view-calendar"
        name="calendar"
        tab
        url="/calendar/"
      ></f7-view>
      <f7-view id="view-search" name="search" tab url="/search/"></f7-view>
      <f7-view id="view-news" name="news" tab url="/news/"></f7-view>
      <f7-view id="view-more" name="more" tab url="/more/"></f7-view>
    </f7-views>

    <initial-config v-if="!zone && online" />

    <f7-popup :opened="!online">
      <f7-page>
        <f7-navbar title="Sei offline"> </f7-navbar>
        <f7-block>
          <h1>Sei offline</h1>
          <p>
            Per funzionare al meglio questa applicazione ha bisogno della
            connessione ad internet.
          </p>
        </f7-block>
      </f7-page>
    </f7-popup>
  </f7-app>
</template>

<script>
import { Device } from "framework7/framework7-lite.esm.bundle.js";
import routes from "../js/routes.js";
import cordovaApp from "../js/cordova-app.js";

export default {
  data() {
    return {
      // Framework7 Parameters
      f7params: {
        id: "it.rifiutiamo.www", // App bundle ID
        name: "RifiutiAMO", // App name
        theme: "auto", // Automatic theme detection
        version: "",

        // App routes
        routes: routes,

        iosTranslucentBars: false,
        iosTranslucentModals: false,

        //Views
        view: {},

        //Links
        clicks: {
          externalLinks: ".gutenberg-embed a, .external",
        },

        // Input settings
        input: {
          scrollIntoViewOnFocus: Device.cordova && !Device.electron,
          scrollIntoViewCentered: Device.cordova && !Device.electron,
        },
        // Cordova Statusbar settings
        statusbar: {
          iosOverlaysWebView: true,
          androidOverlaysWebView: false,
        },
      },
    };
  },

  mounted() {
    this.$f7ready((f7) => {
      const $ = this.$$;

      // Init cordova APIs (see cordova-app.js)
      if (f7.device.cordova) {
        cordovaApp.init(f7);

        cordova.getAppVersion.getVersionNumber().then((version) => {
          f7.version = version;
        });

        this.$store.dispatch("registerToPushNotification");

        cordovaApp.handleMessageReceived();
        document.addEventListener("deviceready", this.offlineDetection, false);
      }

      //Reset active view history
      let lastTab = $(
        "#framework7-root > .views > .toolbar .tab-link.tab-link-active"
      ).attr("data-tab");

      $(document).on(
        "click",
        "#framework7-root > .views > .toolbar .tab-link.tab-link-active",
        function () {
          const currentTab = $(this).attr("data-tab");
          if (currentTab === lastTab) {
            const view = f7.views.get(currentTab);

            if (view.history.length > 1) {
              view.router.back(view.history[0], {
                force: true,
                ignoreCache: true,
              });
            }
          }
          lastTab = currentTab;
        }
      );

      // On click external link open browser
      $(document).on("click", ".gutenberg-embed a", function (e) {
        e.preventDefault();

        const url = $(this).attr("href");

        if (f7.device.cordova && cordova && cordova.InAppBrowser) {
          cordova.InAppBrowser.open(url, "_system", "location=yes");
        }
      });
    });
  },

  methods: {
    handleClickTab(e) {
      console.log(e.target);
    },

    offlineDetection() {
      document.addEventListener(
        "offline",
        () => {
          this.$store.commit("setOnlineStatus", false);
        },
        false
      );

      document.addEventListener(
        "online",
        () => {
          this.$store.commit("setOnlineStatus", true);
        },
        false
      );
    },
  },

  computed: {
    zone() {
      return this.$store.state.device.zone;
    },

    online() {
      return this.$store.state.online;
    },
  },
};
</script>