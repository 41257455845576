<template>
  <div class="wrapper">
    <form @submit="handleSubmit" class="report-form" :id="`report-form-${_uid}`">
      <div v-if="type === 'report'">
        <h3 class="title">Che cosa vuoi fare?*</h3>
        <f7-list>
          <f7-list-item
            radio
            radio-icon="start"
            title="Invia una segnalazione"
            name="type"
            value="report"
            checked
            required
          ></f7-list-item>
          <f7-list-item
            radio
            radio-icon="start"
            title="Invia un reclamo"
            name="type"
            value="claim"
            required
          ></f7-list-item>
          <f7-list-item
            radio
            radio-icon="start"
            title="Richiedi informazioni"
            name="type"
            value="info"
            required
          ></f7-list-item>
        </f7-list>
      </div>

      <input type="hidden" name="type" value="withdrawal" v-if="type === 'withdrawal'">

      <f7-input
        class="form-input"
        type="text"
        name="firstname"
        placeholder="Nome*"
        required
      />
      <f7-input
        class="form-input"
        type="text"
        name="lastname"
        placeholder="Cognome*"
        required
      />
      <f7-input
        class="form-input"
        type="text"
        name="address"
        placeholder="Indirizzo*"
        required
      />

      <f7-list
        v-if="type === 'report'"
        no-hairlines
        class="position-wrapper"
      >
        <f7-list-item v-if="canSendCoord">
          <strong>Invia la tua posizione</strong>
          
          <f7-toggle @change="handleSendPositionChange" id="position-toggle"></f7-toggle>
        </f7-list-item>
      </f7-list>

      <f7-input
        class="form-input"
        type="tel"
        name="phone"
        placeholder="Telefono*"
        required
      />
      <f7-input
        class="form-input"
        name="email"
        type="email"
        placeholder="Email*"
        required
      />
      <div v-if="type === 'withdrawal'">
        <f7-input
          class="form-input"
          type="text"
          name="garbage_type"
          :placeholder="garbageDetailMandatory ? 'Tipologia di rifiuto*' : 'Tipologia di rifiuto'"
          :required="garbageDetailMandatory"
        />
        <f7-input
          class="form-input"
          type="number"
          name="garbage_quantity"
          :placeholder="garbageDetailMandatory ? 'Quantità rifiuto*' : 'Quantità rifiuto'"
          :required="garbageDetailMandatory"
        />
      </div>
      <f7-input
        class="form-input"
        name="note"
        type="textarea"
        placeholder="Note"
      />
      <f7-input
        class="form-input"
        name="images[]"
        type="file"
        accept="image/*"
        multiple
        placeholder="Carica immagini"
        v-if="type === 'report'"
      />

      <f7-button fill large color="blue" type="submit" v-if="type === 'report'">
        Invia*
      </f7-button>
      <f7-button fill large color="blue" type="submit" v-if="type === 'withdrawal'">
        Prenota*
      </f7-button>

      <p class="disclaimer">
        * Confermo di aver preso visione <br> dell'
        <f7-link
          :href="`/pages/${privacyPageId}/`"
        >
          Informativa sulla privacy
        </f7-link>
      </p>
    </form>
  </div>
</template>

<style lang="less" scoped>
  .wrapper {
    margin-top: 30px;
    margin-bottom: 60px; 
  }

  .list {
    margin-top: 10px;
    font-size: 16px;
  }

  .title {
    font-size: 18px;
  }

  .disclaimer {
    font-size: 13px;
  }

  .position-wrapper {
    margin: 10px 0;
  }
</style>

<script>
  import { objectToFormData } from '../js/utils'

  export default {
    props: {
      type: {
        type: String,
        required: false,
        default: 'report',
        validator: function (value) {
          return ['report', 'withdrawal'].indexOf(value) !== -1
        }
      }
    },

    data() {
      return {
        coordinates: null,
      }
    },

    methods: {
      handleSendPositionChange(event) {
        const { checked } = event.target
        
        if (checked && navigator) {
          navigator.geolocation.getCurrentPosition(
            ({ coords: { latitude, longitude } }) => {
              this.coordinates = {
                lat: latitude,
                lng: longitude,
              }
            },
            (error) => {
              this.$f7.dialog.create({
                title: "Errore!",
                text: error.message,
                destroyOnClose: true,
                buttons: [{
                  text: "OK",
                  close: true,
                }],
              }).open()

              this.coordinates = null
              this.$f7.toggle.get('#position-toggle').checked = false
            },
            { timeout: 30000 }
          )
        } else {
          this.coordinates = null
        }
      },

      handleSubmit(e) {
        e.preventDefault()

        this.$f7.dialog.preloader('Invio in corso...')

        const formData = new FormData(e.target)
        const jsonData = this.$f7.form.convertToData(`#report-form-${this._uid}`)
        
        if (this.coordinates) {
          formData.append('lat', this.coordinates.lat)
          formData.append('lng', this.coordinates.lng)
        }

        formData.append('type', jsonData.type)

        this.$store
          .dispatch('setDeviceUser', {
            firstname: jsonData.firstname,
            lastname: jsonData.lastname,
            phone: jsonData.phone,
            email: jsonData.email,
          })
        
        this.$store
          .dispatch('createReport', formData)
          .then((res) => {
            this.$f7.dialog.close();
            this.$f7.dialog.create({
              title: "Invio effettuato con successo!",
              destroyOnClose: true,
              buttons: [{
                text: "OK",
                close: true,
              }],
            }).open()

            this.resetForm()
          })
          .catch((error) => {
            console.log('ko')
            this.$f7.dialog.close();
            this.$f7.dialog.create({
              title: "Invio fallito!",
              text: error.message,
              destroyOnClose: true,
              buttons: [{
                text: "Close",
                close: true,
              }],
            }).open()
          })

      },

      resetForm() {
        this.$$(`#report-form-${this._uid}`)[0].reset()

        if (this.type === 'report') {
          this.$f7.form.fillFromData(`#report-form-${this._uid}`, {
            ...this.user,
            type: 'report',
          })
        } else {
          this.$f7.form.fillFromData(`#report-form-${this._uid}`, {
            ...this.user,
          })
        }
      }
    },

    computed: {
      user() {
        return this.$store.state.user
      },

      canSendCoord() {
        return this.$store.getters.canSendCoord
      },
      
      garbageDetailMandatory() {
        return this.$store.getters.garbageDetailMandatory
      },
      
      privacyPageId() {
        return this.$store.state.options.data.privacy_page
      },
    },

    mounted() {
      this.$store.dispatch('fetchOptions')

      this.resetForm()
    },
  }
</script>