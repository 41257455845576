<template>
  <f7-page name="notifications-page">
    
    <f7-navbar
      back-link
      class="app-header"
      no-hairline
      transparent
    >
      <div class="brand">
        <img src="static/brand.png" />
      </div>
    </f7-navbar>
    
    <f7-block>
      <h1 class="page-title">
        Cambia <br />
        Comune/Zona
      </h1>

      <ul class="info">
        <li>
          <span class="label">
            Comune:
          </span>
          <span class="value">
            {{ municipality && municipality.name }}
          </span>
        </li>
        <li>
          <span class="label">
            Via:
          </span>
          <span class="value" v-html="street && street.title && street.title.rendered">
          </span>
        </li>
      </ul>
    
      <f7-button class="button" fill large popup-open="#change-zone-popup">
        Cambia <f7-icon f7="repeat" size="25" color="white"></f7-icon>
      </f7-button>
    </f7-block>

  </f7-page>
</template>

<style lang="less" scoped>
  .info {
    font-size: 18px;
    padding: 0;
    margin-bottom: 45px;
    list-style: none;

    li {
      margin-bottom: 15px;
      padding-bottom: 15px;
      border-bottom: 1px solid #eee;

      &:last-of-type {
        border-bottom: 0;
      }
    }

    .label {
      display: block;
    }

    .value {
      font-weight: 900;
      font-size: 20px;
    }
  }

  .button {
    background-color: var(--f7-theme-color-secondary);
    font-weight: 700;

    .icon {
      margin-left: 5px;
    }
  }
</style>

<script>
  export default {
    computed: {
      municipality() {
        return this.$store.state.device.municipality
      },

      street() {
        return this.$store.state.device.street
      },
    },
    
    mounted() {
    }
  };
</script>
