<template>
  <f7-page name="single-page">
    
    <f7-navbar
      back-link
      class="app-header"
      no-hairline
      transparent
    >
      <div class="brand">
        <img src="static/brand.png" />
      </div>
    </f7-navbar>
    <div>

      <f7-block v-if="loading" class="skeleton-text skeleton-effect-blink">
        <h1 class="page-title">
          Prima riga del titolo<br />
          Seconda riga<br />
        </h1>
        <h3 class="address">
          Indirizzo del centro di raccolta
        </h3>

        <f7-button
          fill
          large
          color="gray"
        >
        </f7-button>
      </f7-block>

      <f7-block v-else-if="garbageCenter">
        <h1 class="page-title" v-html="garbageCenter.title.rendered">
        </h1>
        <h3 v-if="garbageCenter.address" class="address">
          {{ garbageCenter.address }}
        </h3>

        <f7-button
          v-if="garbageCenter.address"
          class="button"
          fill
          large
          color="blue"
          :href="`https://www.google.it/maps/dir//${garbageCenter.address}`"
          target="_system"
          external
        >
          Indicazioni stradali
        </f7-button>
        
        <div class="content">
          <div class="gutenberg-embed" v-html="garbageCenter.content.rendered">
          </div>
        </div>
      </f7-block>
    </div>
  </f7-page>
</template>

<style lang="less" scoped>
  .page-title {
    color: black;
    margin-bottom: 10px;
    padding-right: 20px; 
  }

  .address {
    font-size: 20px;
    margin-top: 10px;; 
  }

  .button {
    margin: 30px 0;
  }

  .content {
    font-size: 17px;
    line-height: 1.4;
  }
</style>

<script>
  export default {
    methods: {
      goToAddress(address) {
        if (address && directions) {
          directions.navigateToAddress(address)
        }
      }
    },

    computed: {
      garbageCenter() {
        return this.$store.state.garbageCenter.data
      },

      loading() {
        return this.$store.state.garbageCenter.loading
      },
    },
    
    mounted() {
      const id = this.$f7route.params.id;

      this.$store
        .dispatch('fetchGarbageCenter', id)
    }
  };
</script>
