import Vue from 'vue'
import Vuex from 'vuex'
import idx from 'idx'
import Framework7 from 'framework7/framework7-lite.esm.bundle.js'
import uniq from 'lodash.uniq'

import cordovaApp from '../js/cordova-app.js';
import { baseApi } from '../config'

const initialZone = localStorage.getItem('zone-id')
const initialMuniciaplity = JSON.parse(localStorage.getItem('municipality'))
const initialStreet = JSON.parse(localStorage.getItem('street'))
const initialUser = JSON.parse(localStorage.getItem('user'))
const initialPushCategories = JSON.parse(localStorage.getItem('pushCategories'))

Framework7.request.setup({
  headers: {
    'zone-id': initialZone
  }
})
console.log('HANG UP')

Vue.use(Vuex)

const updatePushRegistration = (items, zoneId) => {

  console.log('updatePushRegistration init')

  return new Promise(function(resolve, reject) {
    if (Framework7.device.cordova) {
      const categories = items.map((item) => {
        return `${zoneId}-${item}`
      })
      console.log('updatePushRegistration')
      cordovaApp.registerToPush(categories)
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject()
        })
    } else {
      reject('No cordova platform')
    }
  });

  
}

export default new Vuex.Store({
  state: {
    device: {
      zone: initialZone,
      municipality: initialMuniciaplity,
      street: initialStreet,
    },

    pushCategories: initialPushCategories || [],

    pushFetching: false,
    
    online: true,

    user: initialUser,

    page: {
      data: {},
      loading: false,
      error: false,
    },

    pages: {
      data: [],
      loading: false,
    },

    pagesFeatured: {
      data: [],
      loading: false,
    },

    post: {
      data: null,
      loading: false,
      error: false,
    },

    posts: {
      data: [],
      loading: false,
      page: 1,
      finish: false,
      error: false,
    },

    postsSticky: {
      data: [],
      loading: false,
    },

    garbage: {
      data: null,
      loading: false,
      error: false,
    },

    garbages: {
      data: [],
      loading: false,
      error: false,
    },

    garbagesSelected: {
      data: [],
      loading: false,
      error: false,
    },

    garbageCollections: {
      data: [],
      loading: false,
      error: false,
    },

    garbageCenters: {
      data: [],
      loading: false,
      error: false,
    },

    garbageCenter: {
      data: null,
      loading: false,
      error: false,
    },

    municipalities: {
      data: [],
      loading: false,
      error: false,
    },

    streets: {
      municipality: null,
      data: [],
      loading: false,
      error: false,
    },

    options: {
      data: [],
      loading: false,
      error: false,
    }
  },

  getters: {
    singlePage: (state) => (id) => {
      return idx(state, _ => _.page.data[id])
    },

    pushCategoryValue: (state) => (type) => {
      return idx(state, _ => _.pushCategories.includes(type))
    },

    canSendCoord: state => {
      return idx(state, _ => _.options.data.coord_choose)
    },

    garbageDetailMandatory: state => {
      return idx(state, _ => _.options.data.withdrawal_detail_mandatory)
    }
  },

  actions: {
    // POST
    fetchPost ({ commit, state }, id) {
      commit('setPost', { loading: true })
      
      return Framework7.request.promise.get(`${baseApi}/posts/${id}`, {}, 'json')
        .then(function ({ data, status }) {
          commit('setPost', {
            loading: false,
            error: false,
            data: data,
          })
        })
        .catch(function (error) {
          console.log(error)
          commit('setPost', {
            loading: false,
            error: error,
          })
        })
    },

    // POSTS
    fetchPostsSticky ({ commit, state }, increment = false) {
      if (state.postsSticky.loading) return false

      commit('setPostsSticky', { loading: true })

      return Framework7.request.promise.get(`${baseApi}/posts`, {
          per_page: 1,
          sticky: true,
        }, 'json')
        .then(function ({ data, status }) {
          commit('setPostsSticky', {
            loading: false,
            data: data,
          })
        })
    },

    // POSTS
    fetchPosts ({ commit, state }, increment = false) {
      if (state.posts.loading) return false

      commit('setPosts', { loading: true })

      if (increment) {
        commit('incrementPostsPage')
      }

      const page = state.posts.page

      return Framework7.request.promise.get(`${baseApi}/posts`, {
          page,
        }, 'json')
        .then(function ({ data, status, xhr }) {
          const maxPages = xhr.getResponseHeader('X-WP-TotalPages')

          commit('setPosts', {
            loading: false,
            error: false,
            data: data,
            finish: maxPages <= page
          })
        })
        .catch(function (error) {
          console.log(error)
          commit('setPosts', {
            loading: false,
            error: error,
            finish: true,
          })
        })
    },

    // POSTS
    resetPosts ({ commit, state }) {
      if (state.posts.loading) return false

      commit('setPosts', { loading: true })

      commit('incrementPostsPage', 1)
      const page = state.posts.page
      
      return Framework7.request.promise.get(`${baseApi}/posts`, {
        }, 'json')
        .then(function ({ data, status, xhr }) {
          const maxPages = xhr.getResponseHeader('X-WP-TotalPages')

          commit('resetPosts', {
            loading: false,
            error: false,
            data: data,
            finish: maxPages <= page
          })
        })
        .catch(function (error) {
          console.log(error)
          commit('resetPosts', {
            loading: false,
            error: error,
            data: {},
          })
        })
    },

    // PAGE
    fetchPage ({ commit, state }, id) {
      commit('setPage', { loading: true })
      
      return Framework7.request.promise.get(`${baseApi}/pages/${id}`, {}, 'json')
        .then(function ({ data, status }) {
          commit('setPage', {
            loading: false,
            error: false,
          })
          commit('setPageData', data)
        })
        .catch(function (error) {
          console.log(error)
          commit('setPage', {
            loading: false,
            error: error,
          })
        })
    },

    // PAGES
    fetchPages ({ commit, state }) {
      if (state.pages.loading) return false

      commit('setPages', { loading: true })

      return Framework7.request.promise.get(`${baseApi}/pages`, {
      }, 'json')
        .then(function ({ data, status }) {
          commit('setPages', {
            loading: false,
            data: data,
          })
        })
    },

    // PAGES FEATURED
    fetchPagesFeatured ({ commit, state }) {
      if (state.pagesFeatured.loading) return false

      commit('setPagesFeatured', { loading: true })

      return Framework7.request.promise.get(`${baseApi}/pages/featured`, {
      }, 'json')
        .then(function ({ data, status }) {
          commit('setPagesFeatured', {
            loading: false,
            data: data,
          })
        })
    },

    // GARBAGE COLLECTIONS
    fetchGarbageCollections ({ commit, state }) {
      if (state.garbageCollections.loading) return false

      commit('setGarbageCollections', { loading: true })

      return Framework7.request.promise.get(`${baseApi}/garbage-collections`, {
      }, 'json')
        .then(function ({ data, status }) {
          commit('setGarbageCollections', {
            loading: false,
            error: false,
            data: data,
          })
        })
        .catch(function (error) {
          console.log(error)
          commit('setGarbageCollections', {
            loading: false,
            error: error,
            data: {},
          })
        })
    },

    // GARBAGE
    fetchGarbage ({ commit, state }, id) {
      commit('setGarbage', { loading: true })

      return Framework7.request.promise.get(`${baseApi}/garbages/${id}`, {}, 'json')
        .then(function ({ data, status }) {
          commit('setGarbage', {
            loading: false,
            data: data,
            error: false,
          })
        })
        .catch(function (error) {
          console.log(error)
          commit('setGarbage', {
            loading: false,
            error: error,
          })
        })
    },

    // GARBAGES
    fetchGarbages ({ commit, state }, params = false) {
      commit('setGarbages', { loading: true })

      return Framework7.request.promise.get(`${baseApi}/garbages`, {
        ...params,
      }, 'json')
        .then(function ({ data, status }) {
          commit('setGarbages', {
            loading: false,
            error: false,
            data: data,
          })
        })
        .catch(function (error) {
          console.log(error)
          commit('setGarbages', {
            loading: false,
            error: error,
          })
        })
    },

    // GARBAGES SELECTED
    fetchGarbagesSelected ({ commit, state }) {
      if (state.garbagesSelected.loading) return false

      commit('setGarbagesSelected', { loading: true })

      return Framework7.request.promise.get(`${baseApi}/garbages/selected`, {
        }, 'json')
        .then(function ({ data, status }) {
          commit('setGarbagesSelected', {
            loading: false,
            error: false,
            data: data,
          })
        })
        .catch(function (error) {
          console.log(error)
          commit('setGarbagesSelected', {
            loading: false,
            error: error,
          })
        })
    },

    // GARBAGE CENTERS
    fetchGarbageCenters ({ commit, state }) {
      if (state.garbageCenters.loading) return false

      commit('setGarbageCenters', { loading: true })

      return Framework7.request.promise.get(`${baseApi}/garbage-centers`, {
        }, 'json')
        .then(function ({ data, status }) {
          commit('setGarbageCenters', {
            loading: false,
            data: data,
            error: false,
          })
        })
        .catch(function (error) {
          console.log(error)
          commit('setGarbageCenters', {
            loading: false,
            error: error,
          })
        })
    },

    // SILNGLE GARBAGE CENTER
    fetchGarbageCenter ({ commit, state }, id) {
      commit('setGarbageCenter', { loading: true })
      
      return Framework7.request.promise.get(`${baseApi}/garbage-centers/${id}`, {}, 'json')
        .then(function ({ data, status }) {
          commit('setGarbageCenter', {
            loading: false,
            data: data,
          })
        })
    },

    // MUNICIPALITIES
    fetchMunicipalities ({ commit, state }, params = false) {
      commit('setMunicipalities', { loading: true })

      return Framework7.request.promise.get(`${baseApi}/municipalities`, {
        ...params,
      }, 'json')
        .then(function ({ data, status }) {
          commit('setMunicipalities', {
            loading: false,
            data: data,
            error: false,
          })
        })
        .catch(function (error) {
          console.log(error)
          commit('setMunicipalities', {
            loading: false,
            error: error,
          })
        })
    },

    // STREETS
    fetchStreets({ commit, state }, { municipality, search }) {
      if (!municipality) return false

      commit('setStreets', { loading: true, municipality })

      return Framework7.request.promise.get(`${baseApi}/municipalities/${municipality}/streets`, {
        search,
      }, 'json')
        .then(function ({ data, status }) {
          commit('setStreets', {
            loading: false,
            data: data,
            error: false,
          })
        })
        .catch(function (error) {
          console.log(error)
          commit('setStreets', {
            loading: false,
            error: error,
          })
        })
    },

    //DEVICE ZONE
    setDeviceZone({ commit, state }, zone) {
      commit('setDevice', {
        zone: null
      })

      setTimeout(() => {
        commit('setDevice', {
          zone
        })
        localStorage.setItem('zone-id', zone)
  
        Framework7.request.setup({
          headers: {
            'zone-id': zone,
          }
        })

        updatePushRegistration(state.pushCategories, zone)
      }, 50)
    },

    //DEVICE MUNICIPALITY
    setDeviceMunicipality({ commit, state }, municipality) {
      commit('setDevice', {
        municipality,
      })

      localStorage.setItem('municipality', JSON.stringify(municipality))
    },

    //DEVICE STREET
    setDeviceStreet({ commit, state }, street) {
      commit('setDevice', {
        street,
      })

      localStorage.setItem('street', JSON.stringify(street))
    },

    //DEVICE STREET
    setDeviceUser({ commit, state }, user) {
      commit('setUser', user)

      localStorage.setItem('user', JSON.stringify(user))
    },

    //DEVICE STREET
    registerToPushNotification({ state }, user) {
      updatePushRegistration(state.pushCategories, state.device.zone)
    },

    //DEVICE STREET
    createReport({ commit, state }, formData) {

      return Framework7.request.promise.post(`${baseApi}/reports`, formData, 'json')
    },

    // OPTIONS
    fetchOptions ({ commit }) {
      commit('setOptions', { loading: true })
      
      return Framework7.request.promise.get(`${baseApi}/config`, {}, 'json')
        .then(function ({ data }) {
          commit('setOptions', {
            loading: false,
            error: false,
            data: data,
          })
        })
        .catch(function (error) {
          console.log(error)
          commit('setOptions', {
            loading: false,
            error: error,
          })
        })
    },

  },

  mutations: {
    //PUSH CATEGORIES STREET
    addPushCategory(state, category) {
      if (state.pushFetching) return;

      state.pushFetching = true

      const newCategories = uniq([
        ...state.pushCategories,
        category,
      ])

      console.log('addPushCategory init')

      updatePushRegistration(newCategories, state.device.zone)
        .then(() => {
          console.log('addPushCategory 1')

          state.pushCategories = newCategories

          localStorage.setItem('pushCategories', JSON.stringify(state.pushCategories))
          
          console.log('addPushCategory 2')
        })
        .catch((err) => {
          console.log('updatePushRegistration error:', err)
        }) 
        .then(() => {
          state.pushFetching = false
        })


      
    },

    removePushCategory(state, category) {
      if (state.pushFetching) return;

      state.pushFetching = true

      const newCategories = state.pushCategories.filter(item => item !== category)

      updatePushRegistration(newCategories, state.device.zone)
        .then(() => {
          console.log('removePushCategory 1')
          state.pushCategories = newCategories
          
          localStorage.setItem('pushCategories', JSON.stringify(state.pushCategories))
          console.log('removePushCategory 2')
        })
        .catch((err) => {
          console.log('updatePushRegistration error:', err)
        }) 
        .then(() => {
          state.pushFetching = false
        })
    },

    setOnlineStatus(state, status) {
      state.online = status
    },

    setDevice(state, status) {
      state.device = {
        ...state.device,
        ...status,
      }
    },

    setUser(state, status) {
      state.user = {
        ...state.user,
        ...status,
      }
    },

    setPost(state, status) {
      state.post = {
        ...state.post,
        ...status,
      };
    },

    setPostsSticky(state, status) {
      state.postsSticky = {
        ...state.postsSticky,
        ...status,
      };
    },

    setPosts(state, status, reset) {
      let data = state.posts.data

      //Come si usa lo spread operator tra observable e oggetti normali?
      if (status.data && !reset) {
        data = state.posts.data.concat(status.data)
      }

      state.posts = {
        ...state.posts,
        ...status,
        data,
      };
    },

    resetPosts(state, status) {
      state.posts = {
        ...state.posts,
        ...status,
      };
    },

    incrementPostsPage(state, value){
      if (value) {
        state.posts.page = value
      } else {
        state.posts.page = state.posts.page + 1;
      }
    },

    setPage(state, status){
      const id = status.id
      state.page = {
        ...state.page,
        ...status,
      };
    },

    setPageData(state, status){
      const id = status.id
      state.page.data[id] = status;
    },

    setPages(state, status){
      state.pages = {
        ...state.pages,
        ...status,
      };
    },
    
    setPagesFeatured(state, status){
      state.pagesFeatured = {
        ...state.pagesFeatured,
        ...status,
      };
    },
    
    setGarbageCollections(state, status){
      state.garbageCollections = {
        ...state.garbageCollections,
        ...status,
      };
    },

    setGarbage(state, status){
      state.garbage = {
        ...state.garbage,
        ...status,
      };
    },
    
    setGarbages(state, status){
      state.garbages = {
        ...state.garbages,
        ...status,
      };
    },
    
    setGarbagesSelected(state, status){
      state.garbagesSelected = {
        ...state.garbagesSelected,
        ...status,
      };
    },
    
    setGarbageCenters(state, status){
      state.garbageCenters = {
        ...state.garbageCenters,
        ...status,
      };
    },

    setGarbageCenter(state, status){
      state.garbageCenter = {
        ...state.garbageCenter,
        ...status,
      };
    },
    
    setMunicipalities(state, status){
      state.municipalities = {
        ...state.municipalities,
        ...status,
      };
    },
    
    setStreets(state, status){
      state.streets = {
        ...state.streets,
        ...status,
      };
    },

    setOptions(state, status) {
      state.options = {
        ...state.options,
        ...status,
      };
    },
  },
})