<template>
  <f7-page>
    <!-- <app-header back /> -->

    <f7-navbar
      back-link
      class="app-header"
      no-hairline
      transparent
    >
      <div class="brand">
        <img src="static/brand.png" />
      </div>
    </f7-navbar>

    <f7-block>
      <h1 class="page-title">Nessun risultato</h1>
    </f7-block>
    <f7-block>
      <h3>Il contenuto richiesto non è stato trovato.</h3>
    </f7-block>
  </f7-page>
</template>
<script>
  export default {};
</script>