<template>
  <transition name="fade">
    <f7-block v-if="post">
      <f7-link no-link-class :href="`/news/${post.id}/`">
        <div class="wrapper">
          <span class="label">
            In evidenza
          </span>

          <h3 class="title" v-html="post.title.rendered">
          </h3>
        </div><!--wrapper-->
      </f7-link>
    </f7-block>
  </transition>
</template>

<style lang="less" scoped>
  .wrapper {
    background-color: var(--f7-theme-color-secondary);
    padding: 20px;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 5px;
    min-height: 140px;

    &.loading {
      align-items: center;
      justify-content: center;

      .label {
        background-color: rgba(black, 0.05);
        color: transparent;
      }
    }

    .label {
      margin: 0 0 10px;
      background-color: rgba(black, 0.2);
      padding: 5px 15px 7px;
      border-radius: 100px;
    }

    .title {
      margin: 30px 10px 0 0;
      line-height: 1.2;
      font-size: 23px;
    }
  }

  .fade-enter-active, .fade-leave-active {
    transition: all 0.5s ease;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
</style>

<script>
  export default {
    props: {
      back: {
        type: Boolean,
        default: false,
      },
    },
    
    computed: {
      post() {
        return this.$store.state.postsSticky.data[0]
      },

      loading() {
        return this.$store.state.postsSticky.loading
      },
    },
    
    mounted() {
      this.$store
        .dispatch('fetchPostsSticky')
    }
  }
</script>