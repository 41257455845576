
import HomeTemplate from '../pages/home.vue';
import SearchTemplate from '../pages/search.vue';
import CalendarTemplate from '../pages/calendar.vue';
import NewsTemplate from '../pages/news.vue';
import SingleNewsTemplate from '../pages/single-news.vue';
import MoreTemplate from '../pages/more.vue';
import SinglePageTemplate from '../pages/single-page.vue';
import SingleGarbageTemplate from '../pages/single-garbage.vue';
import NotficationsPage from '../pages/notifications-page.vue';
import ChangeZonePage from '../pages/change-zone-page.vue';
import GarbageCentersPage from '../pages/garbage-centers.vue';
import SingleGarbageCenters from '../pages/single-garbage-center.vue';

import NotFoundTemplate from '../pages/404.vue';

var routes = [
  {
    path: '/',
    component: HomeTemplate,
  },
  {
    path: '/calendar/',
    component: CalendarTemplate,
  },
  {
    path: '/search/',
    component: SearchTemplate,
  },
  {
    path: '/news/',
    component: NewsTemplate,
  },
  {
    path: '/more/',
    component: MoreTemplate,
  },
  {
    path: '/garbages/:id/',
    component: SingleGarbageTemplate,
  },
  {
    path: '/garbage-centers/',
    component: GarbageCentersPage,
  },
  {
    path: '/garbage-centers/:id/',
    component: SingleGarbageCenters,
  },
  {
    path: '/news/:id/',
    component: SingleNewsTemplate,
  },
  {
    path: '/pages/:id',
    component: SinglePageTemplate,
  },
  {
    path: '/notifications/',
    component: NotficationsPage,
  },
  {
    path: '/change-zone/',
    component: ChangeZonePage,
  },
  {
    path: '(.*)',
    component: NotFoundTemplate,
  },
];

export default routes;
