<template>
  <f7-page name="single-news">
    
    <f7-navbar
      back-link
      class="app-header"
      no-hairline
      transparent
    >
      <div class="brand">
        <img src="static/brand.png" />
      </div>
    </f7-navbar>
    <div>

      <f7-block v-if="loading" class="skeleton-text skeleton-effect-blink">
        <h1 class="page-title">
          Prima riga del titolo<br />
          Seconda riga del titolo<br />
        </h1>
      </f7-block>

      <f7-block v-else-if="error">
        <div class="error-wrapper">
          C'è stato un errore nel recupero delle informazioni:
          <i>"{{ error.message }}"</i>
        </div>
      </f7-block>

      <f7-block v-else-if="post">
        <span class="post-date">
          {{ [post.date, 'YYYY-M-DTHH:mm:ss'] | moment('DD/MM/Y') }}
        </span>
        
        <h1 class="page-title" v-html="post.title.rendered ">
        </h1>

        <div class="content">
          <div class="gutenberg-embed" v-html="post.content.rendered">
          </div>
        </div>
      </f7-block>
    </div>
  </f7-page>
</template>

<style lang="less" scoped>
  .post-date {
    color: white;
    font-size: 13px;
    font-weight: 700;
    display: inline-block;
    padding: 4px 10px;
    border-radius: 50px;
    margin-bottom: 20px;
    background-color: var(--f7-theme-color-light);
  }

  .page-title {
    color: black;
    margin-top: 20px;
    margin-bottom: 40px;
  }

  .content {
    font-size: 17px;
    line-height: 1.35;
  }
</style>

<script>
  export default {
    computed: {
      post() {
        return this.$store.state.post.data
      },

      loading() {
        return this.$store.state.post.loading
      },

      error() {
        return this.$store.state.post.error
      },
    },
    
    mounted() {
      const postId = this.$f7route.params.id;

      this.$store
        .dispatch('fetchPost', postId)
    }
  };
</script>
