<template>
  <f7-page name="garbage">
    
    <f7-navbar
      back-link
      class="app-header"
      no-hairline
      transparent
    >
      <div class="brand">
        <img src="static/brand.png" />
      </div>
    </f7-navbar>

    <f7-block v-if="loading" class="skeleton-text skeleton-effect-blink">
      <h1 class="page-title">
        Prima riga del titolo<br />
        Seconda riga del titolo<br />
      </h1>
    </f7-block>

    <f7-block v-else-if="error">
      <div class="error-wrapper">
        C'è stato un errore nel recupero delle informazioni:
        <i>"{{ error.message }}"</i>
      </div>
    </f7-block>

    <div v-else-if="garbage">
      <f7-block>
        <h1 class="page-title" v-html="garbage.title.rendered">
        </h1>

        <h3 class="section-title">
          Dove lo butto?
        </h3>

        <div class="container-type" v-if="containerType">
          <figure class="icon">
            <img :src="containerType.icon" v-if="containerType.icon" />

            <div class="image-placeholder" v-else></div>
          </figure>

          <h4 class="garbage-title">
            {{ containerType.title }}
          </h4>

        </div>

        <div class="gutenberg-embed" v-if="garbage.content && garbage.content.rendered" v-html="garbage.content.rendered">
        </div>

        <f7-button
          v-if="garbageCenter"
          class="button find-garbage-center-button"
          fill
          large
          color="blue"
          href="/garbage-centers/"
          target="_blank"
        >
          Trova centri di raccolta
        </f7-button>

        <!-- Prossime raccolte -->
        <h3 class="section-title" v-if="!garbageCenter && garbage['garbage-collection-events'] && garbage['garbage-collection-events'].length > 0">
          Prossime raccolte:
        </h3>
      </f7-block>

      <next-collection-single-garbage
        v-if="!garbageCenter && garbage['garbage-collection-events']"
        :garbage-collection="garbage['garbage-collection-events']"
      />
      
    </div>
  </f7-page>
</template>

<style lang="less" scoped>
  .page-title {
    color: black;
  }


  .container-type {
    margin: 10px 0;
    position: relative;
    display: flex;
    align-items: center;
    min-height: 45px;
    padding-left: 60px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-top: 1px solid rgba(black, 0.1);
    border-bottom: 1px solid rgba(black, 0.1);
    width: auto;

    .icon {
      height: auto;
      width: 45px;

      top: 10px;
      left: 0;
      padding: 0;
      margin: 0;
      position: absolute;
      display: block;

      img {
        width: 100%;
        height: auto;
      }

      .image-placeholder {
        background-color: #eee;
        height: 45px;
        width: 45px;
        border-radius: 3px;
      }
    }

    .garbage-title {
      font-size: 18px;
      margin: 0;
      font-weight: 900;
    }

    .find-garbage-center-button {
      margin: 20px 0;
    }

    .description {
      color: #888;
      font-weight: 500;
      line-height: 1.2;
      font-size: 15px;
    }
  }
</style>

<script>
  import idx from 'idx'

  export default {
    computed: {
      garbage() {
        return this.$store.state.garbage.data
      },

      containerType() {
        return idx(this.$store.state, _ => _.garbage.data['container-type'])
      },

      garbageCenter() {
        console.log('garbageCenter', this.$store.state.garbage['container-type'])
        return idx(this.$store.state, _ => _.garbage.data['container-type']['garbage-center'])
      },

      loading() {
        return this.$store.state.garbage.loading
      },

      error() {
        return this.$store.state.garbage.error
      },
    },
    
    mounted() {
      const garbageId = this.$f7route.params.id;

      this.$store
        .dispatch('fetchGarbage', garbageId)
    }
  };
</script>
