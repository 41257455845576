<template>
  <div class="wrapper">

    <f7-block class="text-align-center" v-if="loading">
      <f7-preloader></f7-preloader>
    </f7-block>

    <div class="error-wrapper" v-else-if="error">
      C'è stato un errore nel recupero delle prossime raccolte:
      <i>"{{ error.message }}"</i>
    </div>

    <ul class="garbage-calendar" v-else>
      <li v-for="date in dates" :key="date">
        <div class="day" :class="isToday(date) ? 'today' : ''">
          {{ [date, 'YYYY-M-D'] | moment('dddd D MMMM') }}
          

          <span class="today" v-if="isToday(date)">
            Oggi
          </span>
        </div>

        <ul class="garbages" v-if="getGarbagesForDate(date) && getGarbagesForDate(date).length > 0">
          <li v-for="garbage in getGarbagesForDate(date)" :key="garbage.id">
            <figure class="icon">
              <img :src="garbage['container-type'].icon" v-if="garbage['container-type'].icon" />

              <div class="image-placeholder" v-else></div>
            </figure>

            <h4 class="garbage-title">
              {{ garbage['container-type'].title }}
            </h4>
            <div class="description">
              {{ garbage.description }}
            </div>

          </li>
        </ul>
        <div class="no-collection" v-else>
          <span v-if="isToday(date)">
            Non sono previste raccolte per oggi
          </span>
          <span v-else>
            Non sono previste raccolte per questo giorno
          </span>
        </div>
      </li>
    </ul>
  </div>
</template>

<style lang="less" scoped>
  .wrapper {
    margin: 20px;
    border: 0;

    .garbage-calendar {
      list-style: none;
      padding: 0;

      li:first-of-type {
        .day {
          border-top: 0;
        }
      }

      .day {
        position: relative;
        text-transform: capitalize;
        padding: 10px 0;
        font-weight: 900;
        font-size: 20px;
        border-top: 1px solid rgba(black, 0.1);
        border-bottom: 1px solid rgba(black, 0.1);

        &.today {
          display: flex;
          align-items: center;
          justify-content: space-between;

          &:after {
            content: "";
            position: absolute;
            top: 0;
            left: -20px;
            right: -20px;
            bottom: 0;
            background-color: rgba(var(--f7-theme-color-rgb), 0.10);
          }

          .today {
            font-size: 14px;
            font-weight: 900;
            color: rgba(var(--f7-theme-color-rgb), 0.7);
          }
        }
      }

      .no-collection {
        color: #999;
        font-size: 15px;
        font-weight: 500;
        padding: 15px 0 20px;
      }

      .garbages {
        list-style: none;
        padding: 0;

        li {
          margin: 10px 0;
          position: relative;
          padding-left: 60px;
          padding-bottom: 10px;
          width: auto;

          .icon {
            height: auto;
            width: 45px;

            top: 0;
            left: 0;
            padding: 0;
            margin: 0;
            position: absolute;
            display: block;

            img {
              width: 100%;
              height: auto;
            }

            .image-placeholder {
              background-color: #eee;
              height: 45px;
              width: 45px;
              border-radius: 3px;
            }
          }

          .garbage-title {
            font-size: 16px;
            margin: 0;
            font-weight: 900;
          }

          .description {
            color: #888;
            font-weight: 500;
            line-height: 1.2;
            font-size: 15px;
          }
        }
      }
    }

  }
</style>

<script>
  import moment from 'moment'
  import { getNextDays } from '../js/utils'

  export default {
    props: {
      days: {
        type: Number,
        default: 14,
        required: false,
      },

      garbageCollectionEvents: {
        type: Array,
        default: null,
        required: false,
      }
    },

    data() {

      return {
        dates: [],
      }
    },
    
    computed: {
      garbageCollections() {
        return this.$store.state.garbageCollections.data
      },

      loading() {
        return this.$store.state.garbageCollections.loading
      },

      error() {
        return this.$store.state.garbageCollections.error
      },
    },

    methods: {
      isToday(date) {
        const d = moment(date, 'YYYY-M-D')
        return moment().isSame(d, 'day')
      },

      getGarbagesForDate(date) {
        const d = moment(date, 'YYYY-M-D')
        const results = this.garbageCollections.filter((item) => {
          return moment(item.date, 'YYYY-M-D').isSame(d, 'day')
        })

        return results
      },
    },
    
    mounted() {
      this.dates = getNextDays(this.days)

      this.$store
        .dispatch('fetchGarbageCollections')
    },
  }
</script>