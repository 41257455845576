<template>
  <div class="wrapper">
    <f7-row>
      <f7-col width="50" v-for="block in blocks" :key="block.title">
        <f7-link
          no-link-class
          @click="block.view ? $root.goToView(block.view) : null"
          :href="block.href ? block.href : null"
        >
          <div class="block">
            <h3 class="title">
              {{ block.title }}
            </h3>

            <img :src="block.icon" class="icon" />
          </div><!--block-->
        </f7-link>
      </f7-col>
    </f7-row>

    <div class="preloader-wrapper" v-if="loading">
      <f7-preloader></f7-preloader>
    </div>
  </div>
</template>

<style lang="less" scoped>
  .wrapper {
    padding: 20px;

    .block {
      position: relative;
      padding: 10px;
      margin-top: 0;
      margin-bottom: 15px;
      min-height: calc(50vw - 20px);
      border-radius: 10px;
      background-color: var(--f7-theme-color);
      overflow: hidden;

      .title {
        color: white;
        font-size: 16px;
        line-height: 1.2;
        position: relative;
        z-index: 10;
      }

      .icon {
        position: absolute;
        z-index: 9;
        bottom: 10px;
        right: 10px;
        height: 40%;
        width: auto;
      }
    }

    .preloader-wrapper {
      margin: 40px auto;
      width: 100%;
      text-align: center;
    }
  }
</style>

<script>
  import { getBlockFromPages } from '../js/utils'

  const homeBlocks = [
    {
      title: 'Calendario raccolta porta a porta',
      icon: 'static/calendar.svg',
      view: '#tab-calendar',
    },
    {
      title: 'Dove lo butto? Glossario rifiuti',
      icon: 'static/trash.svg',
      view: '#tab-search',
    },
    {
      title: 'Centri di raccolta e contenitori stradali',
      href: '/garbage-centers/',
      icon: 'static/centri.svg',
    },
    {
      title: 'News',
      icon: 'static/news.svg',
      view: '#tab-news',
    },
  ];

  export default {
    methods: {
    },

    computed: {
      blocks() {
        const dynamicBlock = getBlockFromPages(this.$store.state.pagesFeatured.data)

        return [
          ...homeBlocks,
          ...dynamicBlock,
        ]
      },

      loading() {
        return this.$store.state.pagesFeatured.loading
      }
    },
    
    mounted() {
      this.$store
        .dispatch('fetchPagesFeatured')
    }
  }
</script>