<template>
  <f7-page name="search">
    
    <f7-navbar
      class="app-header"
      no-hairline
      :back-link="showBack"
    >
      <div class="brand">
        <img src="static/brand.png" />
      </div>
    </f7-navbar>

    <zone-header />
    
    <f7-block>
      <h1 class="page-title">Dove lo butto?</h1>
      <h2 class="page-subtitle">Glossario rifiuti</h2>
    </f7-block>

    <f7-block class="sticky">
      <div class="input-wrapper">
        <f7-input
          id="search-garbage-input"
          type="text"
          :value="search"
          @input="onSearchGarbage"
          placeholder="Cerca rifiuto"
          class="input"
          clear-button
        ></f7-input>

        <f7-icon f7="search" size="24px" color="primary" class="icon"></f7-icon>
      </div>
    </f7-block>
        <f7-block v-if="search.length <= 0 && !loading && selected.length > 0">
          <h4>
            I rifiuti più cercati
          </h4>
        </f7-block>

    <f7-block>
      <f7-list v-if="loading">
        <f7-list-item
          v-for="n in 3"
          :key="n"
          class="skeleton-text skeleton-effect-blink"
          title="Full Name del rifiuto"
        >
        </f7-list-item>
      </f7-list>

      <f7-list v-else-if="search.length > 0">
        <f7-list-item
          v-for="(garbage) in garbages"
          :key="garbage.id"
          :title="garbage.title && garbage.title.rendered"
          :link="`/garbages/${garbage.id}/`"
        >
        </f7-list-item>

        <div class="error-wrapper" v-if="listError">
          C'è stato un errore nel recupero della lista di rifiuti:
          <i>"{{ listError.message }}"</i>
        </div>

        <div
          v-else-if="garbages.length === 0"
          class="no-results"
        >
          Nessun risultato
        </div>
      </f7-list>

      <f7-list v-else>
        <f7-list-item
          v-for="(garbage) in selected"
          :key="garbage.id"
          :title="garbage.title && garbage.title.rendered"
          :link="`/garbages/${garbage.id}/`"
        >
        </f7-list-item>

        <div class="error-wrapper" v-if="selectedError">
          C'è stato un errore nel recupero della lista di rifiuti:
          <i>"{{ selectedError.message }}"</i>
        </div>

        <div
          v-else-if="selected.length === 0"
          class="no-results"
        >
          Nessun risultato
        </div>

      </f7-list>

      
    </f7-block>
  </f7-page>
</template>

<style lang="less" scoped>
  .sticky {
    background-color: white;
    position: sticky;
    padding: 1px 20px 20px;
    z-index: 100;
    top: 0;
  }

  .input-wrapper {
    background-color: white;
    position: relative;
    border: 1px solid #ccc;
    padding: 15px;
    padding-left: 40px;
    border-radius: 7px;

    .input {
      font-size: 16px;
  
      ::placeholder {
        color: #999;
      }

      &::after {
        content: none;
      }
    }

    .icon {
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
    }
  }

  .no-results {
    color: #999;
  }
</style>

<script>
  import debounce from 'lodash.debounce'

  export default {
    data() {
      return {
        search: '',
        showBack : false,
      }
    },

    methods: {
      onSearchGarbage: debounce( function ({ target: { value } }) {
        this.search = value

        this.$store
          .dispatch('fetchGarbages', { search: value })
      }, 300, {
        leading: true,
        trailing: true,
      }),

      onPageBeforeIn(page) {
        document.querySelector('#search-garbage-input input').focus()
      }
    },

    computed: {
      selected() {
        return this.$store.state.garbagesSelected.data
      },

      garbages() {
        return this.$store.state.garbages.data
      },

      loading() {
        return this.$store.state.garbages.loading || this.$store.state.garbagesSelected.loading
      },

      selectedError() {
        return this.$store.state.garbagesSelected.error
      },

      listError() {
        return this.$store.state.garbages.error
      },
    },
    
    mounted() {
      this.$store
        .dispatch('fetchGarbagesSelected')

      this.showBack = this.$f7router.history.length > 0
    },
  };
</script>
