<template>
  <!-- Top Navbar -->
  <div class="wrapper">
    <div class="zone-wrapper">
      <div class="municipality">
        {{ municipality && municipality.name }}
      </div>
      <div class="street" v-html="street && street.title && street.title.rendered">
      </div>
    </div>
    <div class="change-wrapper">
      <f7-link href="/change-zone/">
        <span>
          Cambia
        </span>
        <f7-icon f7="repeat" size="22" color="primary"></f7-icon>
      </f7-link>
    </div>
  </div>
</template>

<style lang="less" scoped>
  .wrapper {
    padding: 20px;
    background-color: rgba(var(--f7-theme-color-rgb), 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;

    .zone-wrapper {
      width: 60%;
      width: calc(100% - 100px);

      .municipality {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 18px;
        color: black;
        font-weight: 700;
      }

      .street {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 13px;
        font-weight: 600;
        color: rgba(black, 0.4);
      }
    }

    .change-wrapper {
      .link {
        span {
          color: black;
          text-underline-position: under;
          text-decoration: underline;
        }

        .icon {
          color: var(--f7-theme-color-light);
        }
      }
    }
  }
</style>

<script>
  export default {
    computed: {
      municipality() {
        return this.$store.state.device.municipality
      },

      street() {
        return this.$store.state.device.street
      },
    },
    
    mounted() {
    }
  }
</script>