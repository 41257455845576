<template>
  <button class="wrapper">
    <div class="input">
      <span>
        Cerca rifiuto
      </span>

      <f7-icon f7="search" size="24px" color="primary" class="icon"></f7-icon>

    </div>
  </button>
</template>

<style lang="less" scoped>
  .wrapper {
    background-color: var(--f7-theme-color);
    padding: 20px;
    border: 0;
    color: rgba(white, 0.6);

    .input {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px;
      font-size: 18px;
      background-color: var(--f7-theme-color-shade);
      border-radius: 4px;
      text-align: left;
    }
  }
</style>

<script>
  export default {
  }
</script>