<template>
  <f7-page name="calendar">
    <f7-navbar
      class="app-header"
      no-hairline
      :back-link="showBack"
    >
      <div class="brand">
        <img src="static/brand.png" />
      </div>
    </f7-navbar>

    <zone-header />
    
    <f7-block>
      <h1 class="page-title">Calendario</h1>

      <p class="content">
        Consulta il calendario della raccolta porta a porta del tuo comune.
      </p>
    </f7-block>

    <alert-collections-bar />

    <next-collection />

    <div class="spacer"></div>

  </f7-page>
</template>

<style lang="less" scoped>
  .content {
    font-size: 15px;
  }

  .spacer {
    height: 100px;
  }
</style>

<script>
  export default {
    data: function () {
      return {
        showBack: false,
      };
    },

    mounted() {
      this.showBack = this.$f7router.history.length > 0
    },
  };
</script>
